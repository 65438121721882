import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    children: [
      {
        name: 'detail',
        path: 'detail/:id',
        component: () => import(/* webpackChunkName: "detail" */ '../components/PinDetail.vue')
      }
    ]
  }
]

const router = createRouter({
  scrollBehavior (to, from, savedPosition) {
    return {
      top: 0
    }
  },
  history: createWebHashHistory(),
  routes
})

export default router
